/* eslint-disable no-template-curly-in-string */
// src/components/TraderControl.js

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  Paper,
} from "@mui/material";
import io from "socket.io-client"; // Import socket.io-client
import { useNavigate, useLocation } from "react-router-dom";

const TraderControl = () => {
  const [passphrase, setPassphrase] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [traderStatus, setTraderStatus] = useState("stopped"); // Default to 'stopped'
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpSubmitted, setOtpSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSuccessMessage, setOtpSuccessMessage] = useState(""); // Track OTP success
  const [isStarting, setIsStarting] = useState(false); // Track trader starting state
  const [logs, setLogs] = useState([]); // Store backend logs
  const logsEndRef = React.useRef(null);
  const logRef = useRef(null);
  const logEndRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [apiCalled, setApiCalled] = useState(false);
  const [loading, setLoading] = useState(true);  // Loading state to track the fetching of the token

  
  const backendURL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

  useEffect(() => {
    // Extract 'code' param from URL

    if (apiCalled) return;
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    console.log(" hitting backend endpoint of /home");
    // If 'code' exists, send it to the backend
    if (code) {
      console.log("  code here = " , code);
      axios
        .post(`${backendURL}/home`, { code })
        .then((response) => {
          console.log("Backend Response:", response.data);
          // Once backend is called, proceed to render TraderControl
          // navigate("/home"); // You can add state or props if needed
          setLoading(false);  
        })
        .catch((error) => {
          console.error("Error from backend:", error);
          setLoading(false);
        });
        setApiCalled(true);
    } else {
      // If no 'code', just navigate to /home
      navigate("/home");
    }
  }, []);


  // Function to check the trader's status
  const checkTraderStatus = async () => {
    try {
      const response = await axios.get(`${backendURL}/trader_status`); // Adjust based on your backend
      setTraderStatus(response.data.status);
    } catch (error) {
      console.error("Error fetching trader status:", error);
    }
  };

  // Handle passphrase submission
  const handlePassphraseSubmit = async () => {
    try {
      const response = await axios.post(`${backendURL}/enter`, { passphrase });
      if (response.status === 200) {
        setAuthenticated(true);
        setErrorMessage("");
        checkTraderStatus();
      }
    } catch (error) {
      setErrorMessage("Invalid passphrase. Please try again.");
    }
  };

  // Handle starting the trader
  const handleStartLoginTrader = async () => {
    try {
      const response = await axios.post(`${backendURL}/start-login`, {
        passphrase,
      });
      if (response.status === 200) {
        setShowOtpInput(true); // Show OTP input after starting trader
        setErrorMessage("");
      }
    } catch (error) {
      setErrorMessage("Failed to start the trader. Check passphrase.");
    }
  };

  //handle start trader ...
  // Handle starting the trader
  const handleStartTrader = async () => {
    setIsStarting(true);
    try {
      const response = await axios.post(`${backendURL}/start`, { passphrase });
      if (response.status === 200) {
        // setShowOtpInput(true);  // Show OTP input after starting trader
        setErrorMessage("");
        checkTraderStatus();
        // fetchLogs();
      }
    } catch (error) {
      setErrorMessage("Failed to start the trader. Check passphrase.");
    } finally {
      setIsStarting(false); // Hide loading spinner when done
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async () => {
    try {
      const response = await axios.post(`${backendURL}/submit_otp`, { otp });
      if (response.status === 200) {
        setErrorMessage("");
        checkTraderStatus(); // Check trader status after successful OTP submission
        setOtpSubmitted(true);
        setOtpSuccessMessage("OTP successfully submitted!");
        alert("Trader started successfully! Further analysis coming soon...");
        // handleStartTrader();
      }
    } catch (error) {
      setErrorMessage("Invalid OTP. Please try again.");
    }
  };

  // Handle stopping the trader
  const handleStopTrader = async () => {
    try {
      const response = await axios.post(`${backendURL}/stop`, { passphrase });
      if (response.status === 200) {
        checkTraderStatus();
        setOtpSubmitted(false); // Reset OTP submission state when trader stops
        setOtpSuccessMessage("");
      }
    } catch (error) {
      console.error("Error stopping trader:", error);
    }
  };

  // const fetchLogs = async () => {
  //   try {
  //     const response = await axios.get(`${backendURL}/logs`); // Adjust based on your backend logs endpoint
  //     // setLogs(response.data.logs);
  //   } catch (error) {
  //     console.error("Error fetching logs:", error);
  //   }
  // };

  // useEffect(() => {
  //   let interval;
  //   if (isStarting) {
  //     interval = setInterval(fetchLogs, 3000); // Fetch logs every 3 seconds
  //   }
  //   return () => clearInterval(interval); // Cleanup the interval on component unmount
  // }, [isStarting]);

  // Check trader status when authenticated
  useEffect(() => {
    if (authenticated) {
      checkTraderStatus();
    }
  }, [authenticated]);

  useEffect(() => {
    // Establish WebSocket connection to backend
    const socket = io(backendURL);

    socket.on("connect", () => {
        console.log("Connected to WebSocket");
    });

    // Listen for "log" events from the backend (to match the event name in WebSocketHandler)
    socket.on("log", (data) => {
        console.log("Received log:", data.message);
        setLogs((prevLogs) => [...prevLogs, data.message]);
    });

    // Disconnect socket on cleanup
    return () => {
        socket.disconnect();
    };
  }, [backendURL]);

  // Auto-scroll to the latest log
  useEffect(() => {
    if (logEndRef.current) {
      logEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs]);


  const scrollToBottom = () => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [logs]); // Scroll whenever logs are updated

  //   const visibleLogs = logs.slice(-4);

  const assets = ["Nifty", "Bank Nifty", "FinNifty", "Midcap Nifty"]; // Add asset names

  const fetchAssetStatus = async () => {
    try {
      const response = await axios.get("/asset_status");
      // setAssetStatuses(response.data);
    } catch (error) {
      console.error("Error fetching asset status:", error);
    }
  };

  
  const handleDownload = () => { window.open(`${backendURL}/download_log`); };

  // Fetch the status of assets when component mounts
  useEffect(() => {
    fetchAssetStatus();
  }, []);

  if (loading) {
    return <div>Loading... Please wait!</div>;
  }



  return (
    <Box sx={{ p: 4 }}>
      {!authenticated ? (
        <div>
          <Typography variant="h5" gutterBottom>
            Enter Passphrase
          </Typography>
          <TextField
            type="password"
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            label="Passphrase"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handlePassphraseSubmit}
            fullWidth
          >
            Submit
          </Button>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </div>
      ) : (
        <div>
          <Typography variant="h6">Trader Control</Typography>
          <Box
            className={`status-indicator ${
              traderStatus === "running" ? "running" : "stopped"
            }`}
          />
          <p>Status: {traderStatus}</p>

          {!otpSubmitted && traderStatus === "stopped" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartLoginTrader}
              fullWidth
              sx={{ mt: 2 }}
            >
              Send OTP
            </Button>
          )}

          {showOtpInput && !otpSubmitted && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Enter OTP</Typography>
              <TextField
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                label="OTP"
                variant="outlined"
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleOtpSubmit}
                fullWidth
              >
                Submit OTP
              </Button>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
            </Box>
          )}

          {otpSubmitted && (
            <>
              <Typography variant="body1" color="success">
                {otpSuccessMessage}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartTrader}
                fullWidth
                sx={{ mt: 2 }}
                disabled={traderStatus === "running"}
              >
                {isStarting ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Start Trader"
                )}
              </Button>
            </>
          )}

          {isStarting && (
            <Box sx={{ mt: 2 }}>
              <CircularProgress size={40} />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Starting Trader...
              </Typography>
            </Box>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleStopTrader}
            disabled={traderStatus === "stopped"}
            fullWidth
            sx={{ mt: 2 }}
          >
            Stop Trader
          </Button>

          <Button
              variant="contained"
              color="secondary"
              onClick={handleDownload}
              fullWidth
              sx={{ mt: 2 }}
            >
              Download Logs
            </Button>

          {/* Log Display Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Live Logs</Typography>
            <Paper
              variant="outlined"
              sx={{
                height: 300,
                overflowY: "auto",
                p: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              {logs.map((log, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ mb: 0.5, whiteSpace: "pre-wrap" }}
                >
                  {log}
                </Typography>
              ))}
              <div ref={logEndRef} />
            </Paper>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default TraderControl;