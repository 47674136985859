import React, { useEffect } from "react";

// const UpstoxLogin = () => {

//     const backendURL =
//     process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";

//   useEffect(() => {
//     // Initiate login when the component is mounted
//     const loginWithUpstox = async () => {
//       try {
//         // Make a request to the backend to get the Upstox login URL
//         const response = await fetch(`${backendURL}/upstox/login`);
        
//         const { login_url } = await response.json();

//         // Redirect the user to the Upstox login URL
//         // this should already be taken care of i guess
//         window.location.href = login_url;
//       } catch (error) {
//         console.error("Error during Upstox login:", error);
//       }
//     };

//     loginWithUpstox();
//   }, []);

//   return (
//     <div>
//       <h2>Redirecting to Upstox for login...</h2>
//     </div>
//   );
// };

const UpstoxLogin = () => {
    
    const backendURL =
    process.env.REACT_APP_BACKEND_URL || "http://localhost:5000";


    useEffect(() => {
        // Let the backend handle the redirect to Upstox
        window.location.href = `${backendURL}/upstox/login`;
      }, []);
  
    return (
      <div>
        <h2>Redirecting to Upstox for login...</h2>
      </div>
    );
  };
  

export default UpstoxLogin;
