// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Container } from '@mui/material';
import TraderControl from './components/TraderControl';

import UpstoxLogin from './components/upstoxLogin';
import HomeRedirect from './components/HomeRedirect';

function App() {

  return (
    <Router>
      <Container>
        <Routes>
          {/* Route to TraderControl Component */}
          {/* <Route path="/home" element={< HomeRedirect />} /> */}
          <Route path="/home" element={<TraderControl />} />
          <Route path="/" element={<UpstoxLogin />} />
          
          {/* Future Route for PriceFeed or Other Components */}
          {/* <Route path="/price-feed" element={<PriceFeed />} /> */}
          
          {/* Add other routes as needed for future analytics or components */}
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
